export const useAddressRecipe = () => {
  const formatAddress = (addressMayBeRef) => {
    const address = unref(addressMayBeRef)
    if (!address) return 'Error: no address'
    const street = computed(() => {
      const newStreet = address.find((address) => address.type === 'street')
      if (newStreet) return newStreet.value
      return ''
    })
    const housenumber = computed(() => {
      const newHousenumber = address.find(
        (address) => address.type === 'housenumber'
      )
      if (newHousenumber) return newHousenumber.value
      return ''
    })
    const postcode = computed(() => {
      const newPostcode = address.find((address) => address.type === 'postcode')
      if (newPostcode) return newPostcode.value
      return ''
    })
    const city = computed(() => {
      const newCity = address.find((address) => address.type === 'city')
      if (newCity) return newCity.value
      return ''
    })
    const country = computed(() => {
      const newCountry = address.find((address) => address.type === 'country')
      if (newCountry) return newCountry.value
      return ''
    })

    const streetAndNumber = `${street.value} ${housenumber.value}`.trim()
    const postcodeAndCity = `${postcode.value} ${city.value}`.trim()

    // connect streetAndNumber, postcodeAndCity and country wit comma, if they are not empty
    const addressElements = []
    if (streetAndNumber) addressElements.push(streetAndNumber)
    if (postcodeAndCity) addressElements.push(postcodeAndCity)
    if (country.value) addressElements.push(country.value)
    const addressFormated = addressElements.join(', ')

    // const noState = `${street.value} ${housenumber.value}, ${postcode.value} ${city.value}, ${country.value}`

    // // remove double spaces
    // const addressFormated = noState

    return addressFormated
  }

  const getAddress = (itemIdMayBeRef, addressesMayBeRef, maxZoomMayBeRef) => {
    const itemId = unref(itemIdMayBeRef)
    const addresses = unref(addressesMayBeRef)
    let maxZoom = unref(maxZoomMayBeRef)
    if (!itemId) return 'Error: no itemId'
    if (!addresses) return 'Error: no addresses'
    if (!maxZoom) maxZoom = 18

    const countryCode = addresses.find(
      (address) => address.type === 'country_code'
    )
    const newAddresses = []
    if (
      countryCode &&
      countryCode.localname &&
      countryCode.localname.length === 2
    ) {
      let statesAdminLevel = 4
      if (countryCode.localname === 'hr' || countryCode.localname === 'pt') {
        statesAdminLevel = 6
      }
      let citiesAdminLevels = [8, 6]
      newAddresses.push({
        type: 'country',
        country_code: countryCode.localname.toUpperCase(),
        geo_entity_id: null,
        value: null,
        nominatim_address_id: countryCode.id,
      })
      const states = addresses.filter(
        (address) =>
          address.admin_level === statesAdminLevel && address.isaddress
      )
      let cities = null
      if (states && states.length === 1) {
        const state = states[0]
        newAddresses.push({
          type: 'state',
          osm_id: state.osm_id,
          osm_type: state.osm_type,
          geo_entity_id: null,
          value: state.localname,
          nominatim_address_id: state.id,
        })
        if (countryCode.localname === 'ae') {
          // citiesAdminLevel = 4
          citiesAdminLevels = [4]
        }
        if (state.localname === 'England') {
          cities = addresses.filter(
            (address) =>
              (address.type === 'city' ||
                address.type === 'town' ||
                address.type === 'village') &&
              address.isaddress
          )
        }
        if (state.localname === 'New York') {
          // citiesAdminLevel = 5
          citiesAdminLevels = [5]
        }
        if (countryCode.localname === 'de' && state.localname === 'Hamburg') {
          // its a city state
          citiesAdminLevels = [4]
        }
      }
      if (countryCode.localname === 'dk') {
        // Copenhagen
        citiesAdminLevels = [7]
      }
      if (countryCode.localname === 'pt') {
        // citiesAdminLevel = 7
        citiesAdminLevels = [7]
      }

      if (!cities) {
        cities = addresses.filter(
          (address) =>
            citiesAdminLevels.includes(address.admin_level) && address.isaddress
        )
        if (cities && cities.length > 1) {
          citiesAdminLevels.every((citiesAdminLevel) => {
            if (cities.length === 1) return false
            if (cities.find((city) => city.admin_level === citiesAdminLevel)) {
              cities = cities.filter(
                (city) => city.admin_level === citiesAdminLevel
              )
            }
            return true
          })
        }
      }
      if (!cities) {
        if (countryCode.localname === 'hr') {
          cities = addresses.filter(
            (address) =>
              (address.type === 'city' ||
                address.type === 'town' ||
                address.type === 'village') &&
              address.isaddress
          )
        }
      }

      if (cities && cities.length === 1) {
        const city = cities[0]
        newAddresses.push({
          type: 'city',
          osm_id: city.osm_id,
          osm_type: city.osm_type,
          geo_entity_id: null,
          value: city.localname,
          nominatim_address_id: city.id,
        })
      }
      const postcode = addresses.find(
        (address) =>
          address.type === 'postcode' || address.type === 'postal_code'
      )
      if (postcode) {
        newAddresses.push({
          type: 'postcode',
          osm_id: null,
          osm_type: null,
          geo_entity_id: null,
          value: postcode.localname,
          nominatim_address_id: postcode.id,
        })
      }
      const street = addresses.find(
        (address) =>
          address.class === 'highway' &&
          address.isaddress &&
          address.admin_level === 15
      )
      if (street) {
        newAddresses.push({
          type: 'street',
          osm_id: null,
          osm_type: null,
          geo_entity_id: null,
          value: street.localname,
          nominatim_address_id: street.id,
        })
      }
      const housenumber = addresses.find(
        (address) => address.type === 'house_number' && address.isaddress
      )
      if (housenumber) {
        newAddresses.push({
          type: 'housenumber',
          osm_id: null,
          osm_type: null,
          geo_entity_id: null,
          value: housenumber.localname,
          nominatim_address_id: housenumber.id,
        })
      }
      return newAddresses
    }
  }

  return {
    formatAddress,
    getAddress,
  }
}
